import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import EventPreviewGrid from "../components/event-preview-grid"
import SEO from "../components/seo"
import Layout from "../containers/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers"

export const query = graphql`
  query EventsPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)events/" }) {
      id
      _id
      title
      introduction
      _rawBody
    }
    events: allSanityEvent(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          headline
          introduction
          slug {
            current
          }
        }
      }
    }
  }
`

const EventsPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  const eventNodes =
    data &&
    data.events &&
    mapEdgesToNodes(data.events).filter(filterOutDocsWithoutSlugs)

  return (
    <Layout>
      <SEO hidden title="Events" />
      <Header header={page.title} subheader={page.introduction} />
      <Container>
        {eventNodes && eventNodes.length > 0 && (
          <EventPreviewGrid nodes={eventNodes} />
        )}
      </Container>
      <Footer />
    </Layout>
  )
}

export default EventsPage
