import { Link } from "gatsby"
import React from "react"
import emblem from "../images/logo.emblem.png"

import styles from "./header.module.css"
import { responsiveTitle1 } from "../components/typography.module.css"

const Header = ({ header, subheader }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to="/">
          <img src={emblem} alt={header} />
          <span className={styles.hideOnMobile}>TF2Mixup</span>
        </Link>
      </div>

      <nav className={styles.nav}>
        <ul>
          <li className={styles.hideOnMobile}>
            <Link to="/">About</Link>
          </li>
          <li>
            <Link to="/events/">Matches</Link>
          </li>
          <li>
            <Link to="/blog/">News</Link>
          </li>
        </ul>
      </nav>
    </div>

    <header className={styles.header}>
      <h1 className={responsiveTitle1}>{header}</h1>
      <div className={styles.introduction}>{subheader}</div>
    </header>
  </div>
)

export default Header
