import React from "react"

import styles from "./footer.module.css"

const Footer = ({ footer }) => (
  <footer className={styles.root}>
    <div className={styles.siteInfo}>© {new Date().getFullYear()} TF2Mixup</div>
    <div>
      <a href="https://www.netlify.com">
        <img src="https://www.netlify.com/img/global/badges/netlify-light.svg"/>
      </a>
    </div>
  </footer>
)

export default Footer
