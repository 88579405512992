import { Link } from "gatsby"
import React from "react"

import BlockText from "./block-text"

import styles from "./event-preview.module.css"

function EventPreview(props) {
  const { categories } = props
  return (
    <Link className={styles.root} to={`/event/${props.slug.current}`}>
      <h2 className={styles.title}>{props.title}</h2>
      <p>{props.publishedAt}</p>
      {props.introduction}
      {props._rawExcerpt && (
        <div className={styles.excerpt}>
          <BlockText blocks={props._rawExcerpt} />
        </div>
      )}
      {categories && (
        <div className={styles.categories}>
          <ul>
            {categories.map(category => (
              <li key={category._id}>{category.title}</li>
            ))}
          </ul>
        </div>
      )}
    </Link>
  )
}

export default EventPreview
