import React from "react"

import "../styles/layout.css"
import styles from "./layout.module.css"

const Layout = props => (
  <>
    <div className={styles.content}>{props.children}</div>
  </>
)

export default Layout
